import { createRouter, createWebHistory } from 'vue-router'
import { seoGuardWithNext } from '@aminoeditor/vue-router-seo';

const title = 'Multi Solar Systems';
const router = createRouter({

  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      //component: HomeView
      component: () => import('../views/Home.vue'),
      meta: {
        seo: {
          title: `Home | ${title}`,
          metaTags: [
            {
              name: 'description',
              content: ' '
            },
          ],
        }
      },
    },



  ],
  scrollBehavior(to, from, savedPosition) {
      return { top: 0 }
  },
})




// Define the beforeEach navigation guard
router.beforeEach((to, from, next) => {
  if (to.hash) {
    setTimeout(() => {
      const element = document.querySelector(to.hash);
      if (element) {
        // Scroll to the target element
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000); // Adjust the delay as needed (e.g., 100ms, 200ms)
  }
  next();
});

router.beforeEach(seoGuardWithNext)



export default router


import { createApp } from 'vue'
//import './style.css'
import App from './App.vue'
import router from './router'


import './input.css';

import "@fontsource/inter";
import "@fontsource/inter/variable-full.css";


const app = createApp(App)
app.mixin({
    methods: {
        notify(title, message, status, time) {
            const timer = time ? time : 500330
            this.notification = new Array(true, title, message, status);
            setTimeout(()=>{
                this.notification = new Array();
            }, timer)
        },
    }
})

//app.config.globalProperties.globalVar = JSON.parse(document.getElementById('__globalVariables__').textContent)
//app.config.globalProperties.userVar   = JSON.parse(document.getElementById('__userVariables__').textContent)
//app.config.globalProperties.csrfToken = JSON.parse(document.getElementById('__csrfToken__').textContent)

app.config.globalProperties.$filters = {
    readabledate(value) {
        const date = new Date(value);
        return date.getDate() + '-' + (date.getMonth()+ 1) + '-' +  date.getFullYear()
    },
    readabletime(value) {
        const date = new Date(value);
        return String(date.getHours()).padStart(2, '0') + ':' +  String(date.getMinutes()).padStart(2, '0')
    },
    readabledatetime(value) {
        const date = new Date(value);
        return date.getDate() + '-' + (date.getMonth()+ 1) + '-' +  date.getFullYear() + ' - ' +  String(date.getHours()).padStart(2, '0') + ':' +  String(date.getMinutes()).padStart(2, '0')
    },
    websitelink(value) {
        if (value) {
            if ((value.startsWith("https://", 0)) || (value.startsWith("http://", 0)) ){
                return value
            } else {
                return "https://"+value.replaceAll(" ","")
            }
        } else {
            return null
        }
    },
    twodigits(value) {
        try {
            if (value.endsWith(".00")) {
                return Number(value).toFixed(0)
            } else {
                return Number(value).toFixed(2)
            }
        } catch {
            return String('-')
        }
    },
    readablebytes(bytes) {
        var i = Math.floor(Math.log(bytes) / Math.log(1024)),
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        
    return (bytes / Math.pow(1024, 2)).toFixed(2) * 1 + ' ' + sizes[2];
    },
    
    reverse(items) {
        return items.slice().reverse()
    },
  }


app.use(router)
app.mount('#app')
